<template>
  <div class="shipments-page">
    <div class="w-full lg:w-1/2 mb-base">
      <h2>{{$t('Action Center')}}</h2>
    </div>
    <shipblu-card class="p-5">
    <vs-tabs v-model="activeTab" class="tabs-shadow-none" id="profile-tabs">
      <vs-tab v-for="item in tabs" :key="item.index" :label="$t(item)">
        <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
          <action-center-table />
        </div>
      </vs-tab>
    </vs-tabs>
    </shipblu-card>
  </div>
</template>

<script>
import ActionCenterTable from './components/ActionCenterTable.vue'
import ShipbluCard from '../../layouts/components/ShipbluCard.vue'

export default {
  data () {
    return {
      activeTab: '',
      tabs: ['Awaiting Actions', 'In Progress', 'Awaiting Rezoning', 'RTO Hold'],
      tabsDic: {0: 'awaiting-action', 1: 'in-progress', 2: 'awaiting-rezoning', 3: 'rto-hold'},
      tabsNameDic: {'awaiting-action': 0, 'in-progress': 1, 'awaiting-rezoning' : 2, 'rto-hold': 3}
    }
  },
  watch: {
    '$route.params.lang' () {
      this.$router.push({
        query: {
          tab: this.tabsDic[this.activeTab]
        }
      }).catch(() => {})
    },
    activeTab () {
      this.$router.push({
        query: {
          tab: this.tabsDic[this.activeTab],
          page: 1
        }
      }).catch(() => {})
    }
  },
  components: {
    ActionCenterTable,
    ShipbluCard
  },
  created () {
    this.activeTab = this.tabsNameDic[this.$route.query.tab]
  }
}
</script>